/* eslint-disable @typescript-eslint/no-unused-vars */
import axios from "axios";
import { useQuery } from "react-query";

const getUserDetails = async ({ queryKey }: any): Promise<any> => {
  const [_key, email] = queryKey;
  const { data } = await axios.post(
    "https://comms.globalxchange.io/get_affiliate_data_no_logs",
    {
      email: email,
    }
  );
  return data[0];
};

export const useUserDetails = (email?: string) => {
  const query = useQuery(["getUserDetails", email], getUserDetails);
  return query;
};

const getTicketDetail = async ({ queryKey }: any): Promise<any> => {
  const [_key, token] = queryKey;
  const { data } = await axios.get(
    `https://comms.globalxchange.io/coin/investment/path/get?token=${token}`
  );
  if (data.status === false) {
    throw new Error("Invalid Token");
  }
  return data?.paths?.[0];
};
export const useTicketDetail = (token?: string) =>
  useQuery(["getTicketDetail", token], getTicketDetail);

const getUserApps = async ({ queryKey }: any): Promise<any> => {
  const [_key, email] = queryKey;
  const { data } = await axios.get(
    `https://comms.globalxchange.io/gxb/apps/registered/user?email=${email}`
  );
  return data?.userApps;
};
export const useUserApps = (email?: string) =>
  useQuery(["getUserApps", email], getUserApps);

const getVaultBalances = async ({ queryKey }: any): Promise<any> => {
  const [_key, { email, appCode, withBalance, includeCoin }] = queryKey;
  const { data } = await axios.post(
    "https://comms.globalxchange.io/coin/vault/service/coins/get",
    {
      app_code: appCode,
      email: email,
      with_balances: withBalance,
      include_coins: includeCoin,
      orderby_dsc: true,
    }
  );
  return data?.coins_data;
};
export const useVaultBalances = (
  email?: string,
  appCode?: string,
  withBalance: boolean = true,
  includeCoin?: string[]
) =>
  useQuery(
    ["getVaultBalances", { email, appCode, withBalance, includeCoin }],
    getVaultBalances
  );

const getAllMyTickets = async ({ queryKey }: any): Promise<any> => {
  const [_key, email] = queryKey;
  const { data } = await axios.get(
    `https://comms.globalxchange.io/coin/investment/path/user/vault/balances/get?investmentSubType=tickets&email=${email}`
  );
  return data;
};
export const useAllMyTickets = (email?: string) =>
  useQuery(["getAllMyTickets", email], getAllMyTickets);

const getAllTickets = async (): Promise<any> => {
  const { data } = await axios.get(
    `https://comms.globalxchange.io/coin/investment/path/get?investmentSubType=tickets`
  );
  return data?.paths;
};
export const useAllTickets = () => useQuery(["getAllTickets"], getAllTickets);

const getBrokerData = async ({ queryKey }: any): Promise<any> => {
  const [_key, { email, username }] = queryKey;
  const { data } = await axios.get(
    `https://comms.globalxchange.io/user/details/get`,
    {
      params: {
        email,
        username,
      },
    }
  );
  return data?.user;
};

export const useBrokerData = ({
  email,
  username,
}: {
  email?: string;
  username?: string;
}) => useQuery(["getBrokerData", { email, username }], getBrokerData);
