import classNames from "./paymentSidebar.module.scss";
import cryptoCoupon from "../../static/images/logos/cryptoCoupon.svg";
import vaultLogo from "../../static/images/logos/vaultLogo.svg";
import { useEffect, useMemo, useState } from "react";
import { useAppSelector } from "../../app/hooks";
import { selectLoginData } from "../../app/loginSlice";
import { useUserApps, useVaultBalances } from "../../queryHooks";
import Skeleton from "react-loading-skeleton";
import { FormatCurrency } from "../../utils/FunctionTools";
import axios from "axios";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import LoadingAnimation from "../LoadingAnimation";

function PaymentSidebar({
  data,
  count,
  onClose,
}: {
  data: any;
  count: number;
  onClose: (param: boolean) => void;
}) {
  const [step, setStep] = useState("");
  const [app, setApp] = useState<any>();
  const [coin, setCoin] = useState<any>();
  const stepContent = useMemo(() => {
    switch (step) {
      case "success":
        return <Success />;
      case "PaymentSidebarVaultPreview":
        return (
          <PaymentSidebarVaultPreview
            data={data}
            coinSelected={coin}
            appSelected={app}
            count={count}
            setStep={setStep}
          />
        );
      case "PaymentSidebarVaultCoins":
        return (
          <PaymentSidebarVaultCoins
            setStep={setStep}
            coinSelected={coin}
            setCoin={setCoin}
            appSelected={app}
            data={data}
          />
        );
      case "PaymentSidebarVaultApps":
        return (
          <PaymentSidebarVaultApps
            setStep={setStep}
            setApp={setApp}
            appSelected={app}
          />
        );

      default:
        return (
          <PaymentSidebarSelectVault setStep={setStep} onClose={onClose} />
        );
    }
  }, [step, data, coin, app, count, onClose]);
  return <div className={classNames.paymentSidebar}>{stepContent}</div>;
}

function PaymentSidebarSelectVault({
  setStep,
  onClose,
}: {
  setStep: (param: string) => void;
  onClose: (param: boolean) => void;
}) {
  return (
    <>
      <div className={classNames.content}>
        <div className={classNames.header}>
          <div className={classNames.title}>Select Payment Method</div>
          <div className={classNames.breadCrumbs}>
            <span>Method</span>&nbsp;&gt;&nbsp;
          </div>
        </div>
        <input
          className={classNames.searchWrap}
          type="text"
          placeholder="Select The Method You Want To Use..."
        />
        <div className={classNames.actionList}>
          <div
            className={classNames.card}
            onClick={() => setStep("PaymentSidebarVaultApps")}
          >
            <div
              className={classNames.sider}
              style={{
                background: "#050505",
              }}
            />
            <img src={vaultLogo} alt="" className={classNames.cardIcon} />
          </div>
          <div className={classNames.card}>
            <div
              className={classNames.sider}
              style={{
                background: "#5ABEA0",
              }}
            />
            <img src={cryptoCoupon} alt="" className={classNames.cardIcon} />
          </div>
        </div>
      </div>
      <div className={classNames.footer}>
        <div
          className={classNames.footerBtnBack}
          onClick={() => onClose(false)}
        >
          Back
        </div>
        <div className={classNames.footerBtnNext}>Next</div>
      </div>
    </>
  );
}

function PaymentSidebarVaultApps({
  setStep,
  setApp,
  appSelected,
}: {
  setStep: (param: string) => void;
  setApp: (param: any) => void;
  appSelected: any;
}) {
  const { email } = useAppSelector(selectLoginData);
  const [search, setSearch] = useState("");
  const { data: userApps, isLoading } = useUserApps(email);
  return (
    <>
      <div className={classNames.content}>
        <div className={classNames.header}>
          <div className={classNames.title}>Select Payment Method</div>
          <div className={classNames.breadCrumbs}>
            <span onClick={() => setStep("")}>Method</span>&nbsp;&gt;&nbsp;
            <span>App</span>
          </div>
        </div>
        <input
          className={classNames.searchWrap}
          type="text"
          placeholder="Select The App You Want To Purchase From..."
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <div className={classNames.actionList}>
          {isLoading
            ? Array(10)
                .fill("")
                .map((_, i) => (
                  <div className={classNames.cardAppCoin} key={i}>
                    <Skeleton circle className={classNames.icon} />
                    <Skeleton className={classNames.cardName} width={200} />
                  </div>
                ))
            : userApps
                ?.filter(
                  (app: any) =>
                    app.app_name.toLowerCase().includes(search.toLowerCase()) ||
                    app.app_code.toLowerCase().includes(search.toLowerCase())
                )
                .map((app: any) => (
                  <div
                    className={`${classNames.cardAppCoin} ${
                      classNames[
                        (app.app_code === appSelected?.app_code).toString()
                      ]
                    }`}
                    key={app.app_code}
                    onClick={() => setApp(app)}
                  >
                    <img
                      src={app.app_icon}
                      alt=""
                      className={classNames.icon}
                    />
                    <span className={classNames.cardName}>{app.app_name}</span>
                  </div>
                ))}
        </div>
      </div>
      <div className={classNames.footer}>
        <div className={classNames.footerBtnBack} onClick={() => setStep("")}>
          Back
        </div>
        <div
          className={classNames.footerBtnNext}
          onClick={() => setStep("PaymentSidebarVaultCoins")}
        >
          Next
        </div>
      </div>
    </>
  );
}

function PaymentSidebarVaultCoins({
  setStep,
  coinSelected,
  setCoin,
  appSelected,
  data,
}: {
  setStep: (param: string) => void;
  setCoin: (param: any) => void;
  coinSelected: any;
  appSelected: any;
  data: any;
}) {
  const { email } = useAppSelector(selectLoginData);
  const [search, setSearch] = useState("");

  const { data: coinList, isLoading } = useVaultBalances(
    email,
    appSelected?.app_code,
    data?.token_price ? true : false,
    data?.token_price ? undefined : ["INR"]
  );
  return (
    <>
      <div className={classNames.content}>
        <div className={classNames.header}>
          <div className={classNames.title}>Select Payment Method</div>
          <div className={classNames.breadCrumbs}>
            <span onClick={() => setStep("")}>Method</span>&nbsp;&gt;&nbsp;
            <span onClick={() => setStep("PaymentSidebarVaultApps")}>App</span>
            &nbsp;&gt;&nbsp;
            <span>Vault</span>
          </div>
        </div>
        <input
          className={classNames.searchWrap}
          type="text"
          placeholder="Select The Vault You Want To Purchase From..."
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <div className={classNames.actionList}>
          {isLoading
            ? Array(10)
                .fill("")
                .map((_, i) => (
                  <div className={classNames.cardAppCoin} key={i}>
                    <Skeleton circle className={classNames.icon} />
                    <Skeleton className={classNames.cardName} width={200} />
                  </div>
                ))
            : coinList
                ?.filter(
                  (coin: any) =>
                    coin.coinName
                      .toLowerCase()
                      .includes(search.toLowerCase()) ||
                    coin.coinSymbol.toLowerCase().includes(search.toLowerCase())
                )
                ?.map((coin: any) => (
                  <div
                    className={`${classNames.cardAppCoin} ${
                      classNames[
                        (
                          coin.coinSymbol === coinSelected?.coinSymbol
                        ).toString()
                      ]
                    }`}
                    key={coin.coinSymbol}
                    onClick={() => setCoin(coin)}
                  >
                    <img
                      src={coin.coinImage}
                      alt=""
                      className={classNames.icon}
                    />
                    <div className={classNames.cardName}>
                      {coin.coinName}
                      <span>
                        {FormatCurrency(coin.coinValue, coin.coinSymbol)}
                      </span>
                    </div>
                  </div>
                ))}
        </div>
      </div>
      <div className={classNames.footer}>
        <div
          className={classNames.footerBtnBack}
          onClick={() => setStep("PaymentSidebarVaultApps")}
        >
          Back
        </div>
        <div
          className={classNames.footerBtnNext}
          onClick={() => setStep("PaymentSidebarVaultPreview")}
        >
          Next
        </div>
      </div>
    </>
  );
}

const buyTicketApi = async (paramData: any) => {
  const { data } = await axios.post(
    "https://comms.globalxchange.io/coin/investment/path/execute",
    paramData
  );
  return data;
};

function PaymentSidebarVaultPreview({
  data,
  coinSelected,
  appSelected,
  count,
  setStep,
}: {
  data: any;
  coinSelected: any;
  appSelected: any;
  count: number;
  setStep: (param: string) => void;
}) {
  const { email, token } = useAppSelector(selectLoginData);
  const {
    isLoading: loading,
    mutate,
    data: mutateData,
  } = useMutation(buyTicketApi, {
    onSuccess: (data) => {
      if (data.status) {
        if (!data.investmentAsset_stats) {
          setStep("success");
        }
      } else {
        toast.error(data.message);
      }
    },
  });
  const postData = useMemo(() => {
    return {
      email,
      token,
      app_code: appSelected?.app_code,
      profile_id: appSelected?.profile_id,
      path_id: data?.path_id,
      user_pay_coin: coinSelected?.coinSymbol,
      tokens_amount: count,
    };
  }, [
    email,
    token,
    appSelected?.app_code,
    appSelected?.profile_id,
    data?.path_id,
    coinSelected?.coinSymbol,
    count,
  ]);

  useEffect(() => {
    mutate({
      ...postData,
      stats: true,
    });
    return () => {};
  }, [mutate, postData]);

  return (
    <>
      <div className={classNames.headerArea}>
        <div className={classNames.img}>
          <img src={data?.token_profile_data?.coinImage} alt="" />
        </div>
      </div>
      <div className={classNames.headerPreview}>
        <div className={classNames.title}>
          {data?.token_profile_data?.coinName}
        </div>
        <div className={classNames.count}>
          {count} Ticket{count === 1 ? "" : "s"}
        </div>
      </div>
      <div className={classNames.detail}>
        <div className={classNames.deatailTitle}>Total Cost</div>
        <div className={classNames.details}>
          <div className={classNames.detailItem}>
            <div className={classNames.coin}>
              <img
                src={coinSelected?.coinImage}
                alt=""
                className={classNames.coinImage}
              />
              <span>{coinSelected?.coinName}</span>
            </div>
            <div className={classNames.value}>
              {FormatCurrency(
                mutateData?.finalFromAmount,
                coinSelected?.coinSymbol
              )}
            </div>
          </div>
        </div>
      </div>
      <div className={classNames.detail}>
        <div className={classNames.deatailTitle}>Checkout Summary</div>
        <div className={classNames.details}>
          <div className={classNames.detailItem}>
            <div className={classNames.label}>App</div>
            <div className={classNames.coin}>
              <img
                src={appSelected?.app_icon}
                alt=""
                className={classNames.coinImage}
              />
              <span>{appSelected?.app_name}</span>
            </div>
          </div>
          <div className={classNames.detailItem}>
            <div className={classNames.label}>Vault</div>
            <div className={classNames.coin}>
              <img
                src={coinSelected?.coinImage}
                alt=""
                className={classNames.coinImage}
              />
              <span>{coinSelected?.coinName}</span>
            </div>
          </div>
          <div className={classNames.detailItem}>
            <div className={classNames.label}>Amount</div>
            <div className={classNames.value}>
              {FormatCurrency(
                mutateData?.investmentAsset_stats?.debitAmount,
                coinSelected?.coinSymbol
              )}{" "}
              {coinSelected.coinSymbol}
            </div>
          </div>
        </div>
      </div>
      <div className={classNames.footer}>
        <div
          className={classNames.footerBtnBack}
          onClick={() => setStep("PaymentSidebarVaultCoins")}
        >
          Back
        </div>
        <div
          className={classNames.footerBtnNext}
          onClick={() => {
            mutate({
              ...postData,
              stats: false,
            });
          }}
        >
          Next
        </div>
      </div>
      {loading && <LoadingAnimation />}
    </>
  );
}

function Success() {
  const navigate = useNavigate();
  useEffect(() => {
    setTimeout(() => {
      navigate("/");
    }, 1500);
  }, [navigate]);

  return (
    <>
      <div className={classNames.success}>
        Your Tickets Have Successfully Been Purchased.
      </div>
      <div className={classNames.successFooter}>
        <span>You Will Be Redirected To The Home Page</span>
      </div>
      <div className={classNames.footerHide} />
    </>
  );
}

export default PaymentSidebar;
